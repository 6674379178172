<template>
  <div id="access-editor">
    <nav class="ant-navbar text-right">
      <a-space align="center" size="middle">
        <search-expandable size="large" placeholder="Поиск по наименованию" v-model="search"
                           @enter="loadData"/>
        <a-button size="large" @click="modalAddShow = true">Добавить доступ</a-button>
      </a-space>
    </nav>

    <a-table class="offset-lg" :columns="columns" :data-source="attributes" row-key="id"
             :default-expand-all-rows="false"
             :expanded-row-keys="expandedRowKeys" @expand="onExpandChange"
             :indent-size="25" :loading="loading"
             children-column-name="policies" :pagination="{ hideOnSinglePage: true }"
             :locale="{ emptyText: isFilterSelected ? 'Ничего не найдено' : 'Нет данных' }">
      <template #expandIcon="{ expanded, onExpand, record, expandable }">
        <right-outlined v-if="expandable" :rotate="expanded ? 90 : 0" @click="onExpand(record)"
                        :style="{ marginRight: '0.5rem' }"/>
      </template>
      <template #objectType="{ record }">
        <span v-if="!record.entityName">
          {{ record.objectType?.name }}
        </span>
      </template>
      <template #objectName="{ record }">
        <span v-if="record?.entityName">{{ record?.entityName }}</span>
      </template>
      <template #actions="{ record }">

        <nav class="action-container" v-if="record?.entityName">
          <div class="checkboxes">
            <a-checkbox v-model:checked="record.canView" :disabled="loading"
                        @change="changeAccess(record)">
              Просмотр
            </a-checkbox>
            <a-checkbox v-model:checked="record.canReserve" :disabled="loading"
                        @change="changeAccess(record)">
              Бронирование
            </a-checkbox>
          </div>
          <a-popconfirm title="Удалить этот доступ?"
                        ok-text="Удалить" cancel-text="Отмена"
                        @confirm="deleteObjectHandler(record)">
            <a-button type="dashed">
              <template #icon>
                <close-outlined/>
              </template>
            </a-button>
          </a-popconfirm>
        </nav>

      </template>
    </a-table>

    <!-- Окно добавление сущности: элемента или группы -->
    <a-modal v-model:visible="modalAddShow" title="Добавить доступ">
      <a-form
          ref="refFormAdd"
          :model="formAdd"
          layout="vertical"
      >
        <a-form-item label="Тип объекта *" name="objectTypeId" :rules="[
          { required: true, message: 'Выберите тип объекта', trigger: 'blur' },
        ]">
          <a-select v-model:value="formAdd.objectTypeId"
                    show-search
                    :default-active-first-option="false"
                    :show-arrow="true"
                    :filter-option="false"
                    :not-found-content="null"
                    @search="getObjectTypes"
                    placeholder="Выберите тип" size="large">
            <a-select-option :value="option.id" v-for="(option) in objectTypesData" :key="option.id">
              {{ option.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="Добавить">
          <a-radio-group name="entityType" v-model:value="formAdd.entityType" @change="entityTypeChange">
            <a-radio value="group">Группу</a-radio>
            <a-radio value="user">Пользователя</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item :label="formAdd.entityType === 'group' ? 'Группа *' : 'Пользователь *'" name="entityId" :rules="[
          { required: true, message: 'Выберите вариант из списка', trigger: 'change' },
        ]">
          <a-select v-model:value="formAdd.entityId" v-if="formAdd.entityType === 'group'"
                    show-search
                    :default-active-first-option="false"
                    @search="fetchGroupList"
                    :loading="groupLoading"
                    :show-arrow="true"
                    :filter-option="false"
                    :not-found-content="null"
                    placeholder="Введите название группы" size="large">
            <a-select-option :value="option.id" v-for="(option) in groupList" :key="option.id"
                             :disabled="objectIds.indexOf(option.id) !== -1">
              {{ option.name }}
            </a-select-option>
          </a-select>
          <a-select v-model:value="formAdd.entityId" v-else
                    show-search
                    :default-active-first-option="false"
                    @search="fetchUserList"
                    :loading="userLoading"
                    :show-arrow="true"
                    :filter-option="false"
                    :not-found-content="null"
                    placeholder="Введите логин пользователя" size="large">
            <a-select-option :value="option.id" v-for="(option) in userList" :key="option.id"
                             :disabled="objectIds.indexOf(option.id) !== -1">
              {{ [option.lastName, option.firstName, option.attributes?.middleName].join(' ') }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>

      <template #footer class="buttons-fit">
        <a-button key="back" size="large" @click="modalAddShow = false">Отменить</a-button>
        <a-button key="submit" size="large" type="primary" :loading="loadingProgress"
                  :disabled="!formAdd.objectTypeId || !formAdd.entityId"
                  @click="addObjectHandler">
          Добавить
        </a-button>
      </template>
    </a-modal>

  </div>
</template>

<script>
import { computed, ref, unref, reactive } from "@vue/reactivity";
import { nextTick, onMounted, watch } from "@vue/runtime-core";
import {
  RightOutlined,
  CloseOutlined,
} from '@ant-design/icons-vue';
import SearchExpandable from "@/components/common/SearchExpandable";
import { getListData as getObjectTypesData } from "@/compositions/objectTypes";
import { notification } from "ant-design-vue";
import { requestAPI } from "@/compositions/objects";
import { getUserListData, getGroupListData } from "@/compositions/userAndGroups";

export default {
  name: 'AccessEditor',
  props: {
    objectId: String,
  },
  setup(props) {
    const attributes = ref([]);

    const expandedRowKeys = ref([]);
    const search = ref('');
    const loading = ref(true);
    const loadingProgress = ref(false);
    const modalAddShow = ref(false);
    const refFormAdd = ref(null);
    const formAdd = reactive({
      entityType: 'user',
      objectTypeId: undefined,
      canView: true,
      canReserve: true,
    });

    const { getObjectReservationPolicy, updateReservationPolicy, removeReservationPolicy } = requestAPI();
    const { loading: userLoading, fetchUserList, data: userList } = getUserListData();
    const { loading: groupLoading, fetchGroupList, data: groupList } = getGroupListData();
    const isFilterSelected = ref(false);

    const {
      fetchList: fetchObjectTypes,
      data: objectTypesData,
      search: objectTypesSearch,
    } = getObjectTypesData();

    const objectIds = computed(() => {
      const res = [];
      if (attributes.value) {
        attributes.value.forEach((group) => {
          group.policies.forEach((politic) => res.push(politic.id));
        })
      }
      return res;
    });

    async function getObjectTypes(search) {
      objectTypesSearch.value = search;
      fetchObjectTypes();
    }

    onMounted(async () => {
      // Данные для формы грузить параллельно
      fetchObjectTypes();
      fetchUserList();
      fetchGroupList();

      await loadData();
      // Открываем все подразделы
      attributes.value.forEach((node) => {
        expandedRowKeys.value.push(node.id);
      });
    });

    const columns = [
      {
        title: 'Тип объекта',
        width: '33%',
        ellipsis: true,
        slots: { customRender: 'objectType' },
      },
      {
        title: 'Наименование',
        slots: { customRender: 'objectName' },
      },
      {
        title: 'Доступно',
        width: 400,
        slots: { customRender: 'actions' },
      },
    ];

    async function addObjectHandler() {
      await refFormAdd.value
          .validate()
          .then(async () => {
            loadingProgress.value = true;
            const node = await updateReservationPolicy(unref(formAdd), props.objectId);
            if (node?.objectType) {
              expandedRowKeys.value.push(node?.objectType?.id);
              modalAddShow.value = false;
              loadingProgress.value = false;
              notification.success({
                message: 'Успех',
                description: 'Доступ добавлен',
              });
              await loadData();
            } else {
              notification.error({
                message: 'Ошибка',
                description: 'Сохранить не удалось. Попробуйте позже',
              });
            }
          })
          .finally(() => {
            loadingProgress.value = false;
          });
    }

    async function deleteObjectHandler(entity) {
      await removeReservationPolicy(props.objectId, entity.id);
      notification.success({
        message: 'Успех',
        description: 'Строка успешно удалена',
      });
      await loadData();
    }

    function onExpandChange(expanded, node) {
      if (expanded) {
        expandedRowKeys.value.push(node.id);
      } else {
        const indexToDelete = expandedRowKeys.value.indexOf(node.id);
        if (indexToDelete > -1) {
          expandedRowKeys.value.splice(indexToDelete, 1);
        }
      }
    }

    async function changeAccess(entity) {
      await updateReservationPolicy(entity, props.objectId, entity.id);
      notification.success({
        message: 'Успех',
        description: 'Изменения сохранены',
      });
    }

    function entityTypeChange() {
      try {
        // Очистить форму
        formAdd.entityId = undefined;
        nextTick(() => refFormAdd.value.clearValidate('entityId'));
      } catch (e) {
        console.error(e);
      }
    }

    async function loadData() {
      loading.value = true;
      isFilterSelected.value = !!search.value;
      const res = await getObjectReservationPolicy(props.objectId, { search: unref(search), perPage: 999 });
      attributes.value = res.data;

      // Добавим id на глобальный уровень для поддержки раскрываемости
      attributes.value.forEach((node) => {
        node.id = node.objectType?.id;
        node.policies.forEach((entity) => {
          entity.objectTypeId = node.objectType.id;
        });
      });

      loading.value = false;
    }

    watch(modalAddShow, (open, close) => {
      // Если закрываем, то очищаем форму
      if (close && !open) {
        try {
          // Очистить форму
          formAdd.objectTypeId = undefined;
          formAdd.entityId = undefined;
          refFormAdd.value.clearValidate();
          // Загрузить данные для форм снова
          fetchObjectTypes();
          fetchUserList();
          fetchGroupList();
        } catch (e) {
          console.error(e);
        }
      }
    })

    return {
      columns, attributes, search, isFilterSelected,
      loading, loadingProgress, formAdd, refFormAdd,
      expandedRowKeys, onExpandChange, entityTypeChange,
      addObjectHandler, deleteObjectHandler, changeAccess, loadData,
      modalAddShow, fetchObjectTypes, objectTypesData, objectTypesSearch, getObjectTypes,
      userLoading, fetchUserList, userList,
      groupLoading, fetchGroupList, groupList,
      objectIds,
    }
  },
  components: { SearchExpandable, RightOutlined, CloseOutlined },
}
</script>

<style lang="less">
#access-editor {
  td.col-attribute-name {
    display: flex;
    align-items: center;
  }

  .action-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }

  .checkboxes {
    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin-left: 2rem;
    }
  }
}
</style>

<template>
  <section>
    <nav class="ant-navbar text-right">
      <a-space align="center" size="middle">
        <a-button size="large">
          <router-link :to="{ name: 'reservations', params: { objectId, searchMode: 'reservations' } }">
            Поиск бронирований
          </router-link>
        </a-button>
        <a-button size="large">
          <router-link :to="{ name: 'reservations', params: { objectId, searchMode: 'free_slots' } }">
            Создать бронирование
          </router-link>
        </a-button>
      </a-space>
    </nav>
    <a-table :columns="columns" :data-source="data" :pagination="pagination" row-key="id"
             :loading="loading" @change="handleTableChange"
             :row-class-name="(record) => (record.status === 'free' ? 'row-show-select-btn' : '')"
             :locale="{ emptyText: isFilterSelected ? 'Ничего не найдено' : 'Нет данных', filterConfirm: 'Применить', filterReset: 'Сбросить' }">
      <template #time="{ text }">
        <span>{{ moment(text, 'YYYY-MM-DDTHH:mm:ss').format('DD.MM.YYYY | HH:mm') }}</span>
      </template>
      <template #user="{ record }">
        {{ record.user?.name || '—' }}
      </template>
      <template #objectName="{ record }">
        <a-tooltip :title="renderObjectPath(record.object?.path || [], false) || 'Верхний уровень'">
          <router-link class="text-color-text"
                       :to="{ name: record?.object?.hasChildren ? 'objectChildren' : 'objectView', params: { id: record.object?.id } }">
            {{ record.object?.name || '' }}
          </router-link>
        </a-tooltip>
      </template>
      <template #objectCode="{ record }">
        {{ record.object?.code || '' }}
      </template>
      <template #status="{ text }">
        <span v-if="text === 'reserved'" class="primary-color-text">Забронирован</span>
        <span v-if="text === 'free'" class="primary-light-blue-text">Свободно</span>
        <span v-if="text === 'complete'" class="light-gray-color-text">Завершен</span>
        <span v-if="text === 'in_use'" class="success-color-text">Используется</span>
        <span v-if="text === 'fictive'" class="error-color-text">Не используется</span>
      </template>
      <template #actions="{ record }">
        <a-dropdown :trigger="['click']">
          <template #overlay>
            <a-menu>
              <a-menu-item key="edit" v-if="record?.status === 'reserved'">
                <router-link :to="{ name: 'reservationView', params: {id: record.id} }">
                  <edit-outlined/>
                  Редактировать
                </router-link>
              </a-menu-item>
              <a-menu-item key="view" v-else>
                <router-link :to="{ name: 'reservationView', params: {id: record.id} }">
                  <eye-outlined/>
                  Просмотр
                </router-link>
              </a-menu-item>
              <a-menu-item key="move" @click="handleDelete(record.id)">
                <delete-outlined/>
                Удалить
              </a-menu-item>
            </a-menu>
          </template>
          <a-button key="more" type="dashed" size="large">
            <more-outlined/>
          </a-button>
        </a-dropdown>

      </template>
    </a-table>
  </section>
</template>

<script>
import { onMounted } from "@vue/runtime-core";
import { getListData as getReservationData, requestAPI as reservationAPI } from "@/compositions/reservations";
import { getOneData as getOneObject } from "@/compositions/objects";
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  MoreOutlined,
} from '@ant-design/icons-vue';
import { Modal, notification } from "ant-design-vue";
import moment from "moment";

export default {
  name: 'ObjectReservations',
  props: {
    objectId: String,
  },
  setup(props) {
    const {
      loading, columns, data, handleTableChange, pagination,
      formSearchDate, formSearch, findReservation, initFilterDefaults
    } = getReservationData();
    const { renderObjectPath } = getOneObject();
    const { removeReservation } = reservationAPI();

    onMounted(async () => {
      await initFilterDefaults();
      formSearch.objectId = props.objectId;
      formSearchDate.dateTo = moment().add(2, 'years').format('YYYY-MM-DD');
      await findReservation();
    });

    async function handleDelete(id) {
      Modal.confirm({
        title: 'Удалить бронирование?',
        okText: 'Удалить',
        okType: 'danger',
        cancelText: 'Отмена',
        async onOk() {
          await removeReservation(id).then(() => {
            notification.success({
              message: 'Успех',
              description: 'Бронирование успешно удалено',
            });
            findReservation();
          });
        },
        onCancel() {
        },
      });
    }

    return {
      moment, renderObjectPath,
      loading, columns, data, handleTableChange, pagination,
      handleDelete,
    }
  },
  components: {
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    MoreOutlined,
  }
}
</script>

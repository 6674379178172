<template>
  <section>
    <a-breadcrumb>
      <a-breadcrumb-item v-for="(pathItem) in objectPath" :key="pathItem.id">
        <router-link v-if="pathItem.name === 'root'" :to="{ name: 'objects' }">Верхний уровень</router-link>
        <router-link v-else :to="{ name: 'objects', params: { id: pathItem.id } }">{{ pathItem.name }}</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>{{ pageTitle }}</a-breadcrumb-item>
    </a-breadcrumb>
    <a-page-header :title="pageTitle"
                   @back="() => $router.push(backRoute?.name === 'root' ? { name: 'objects' } : { name: 'objects', params: { id: backRoute.id } })"
                   :class="{ loading }">
      <template #extra>
        <a-space align="center" size="middle">
          <a-button type="link" v-if="formsSaved['map']?.mapImage">
            <template #icon>
              <environment-outlined/>
            </template>
            <router-link :to="{ name: 'mapViewAdmin', params: { id: formInfo?.id } }">
              На карту
            </router-link>
          </a-button>

          <a-button key="delete" size="large" @click="handleDelete">
            <template #icon>
              <delete-outlined/>
            </template>
          </a-button>
        </a-space>
      </template>
    </a-page-header>

    <main v-if="loading">
      <a-skeleton/>
    </main>
    <main v-else>
      <a-tabs v-model:active-key="currentTab">
        <!--  Основное  -->
        <a-tab-pane key="info" tab="Основное">
          <a-form class="offset-lg" :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }"
                  ref="refFormInfo" :model="formInfo">
            <a-form-item label="Код объекта" name="code" :rules="[
              { required: true, message: 'Введите код', trigger: 'blur' },
              { pattern: /^[a-zA-z\d\w-]+$/, message: 'Доступны только латинские буквы, цифры и тире', trigger: 'blur' },
              { min: 1, max: 255, message: 'Длина должна быть от 1 до 255 символов', trigger: 'change' }
            ]">
              <a-input v-model:value="formInfo.code" placeholder="Введите уникальный код">
                <template #suffix>
                  <a-tooltip title="Получить уникальный код">
                    <span class="cursor-pointer" @click="getUniqueCode">
                      <reload-outlined/>
                    </span>
                  </a-tooltip>
                </template>
              </a-input>
            </a-form-item>
            <a-form-item label="Название объекта" name="name" :rules="[
              { required: true, message: 'Введите название', trigger: 'blur' },
              { min: 1, max: 255, message: 'Длина должна быть от 1 до 255 символов', trigger: 'change' }
            ]">
              <a-input v-model:value="formInfo.name" placeholder="Введите название"/>
            </a-form-item>
            <a-form-item label="Тип объекта" name="objectTypeId" :rules="[
              { required: true, message: 'Выберите тип', trigger: 'blur' },
            ]">
              <a-select v-model:value="formInfo.objectTypeId"
                        show-search
                        :default-active-first-option="false"
                        :show-arrow="true"
                        :filter-option="false"
                        :not-found-content="null"
                        @search="getObjectTypes"
                        @change="onObjectTypeChanged"
                        placeholder="Введите текст для поиска" size="large">
                <a-select-option :value="option.id" v-for="(option) in objectTypesData" :key="option.id">
                  {{ option.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="Политика доступов" name="reservationPolicyId" :rules="[
              { message: 'Пожалуйста, выберите вариант', trigger: 'blur' },
            ]">
              <a-select v-model:value="formInfo.reservationPolicyId" show-search
                        :default-active-first-option="false"
                        :show-arrow="true" allow-clear
                        :filter-option="false"
                        :not-found-content="null"
                        @search="getPolitics"
                        placeholder="Выберите политику" size="large">
                <a-select-option :value="option?.id" v-for="(option) in politicsList" :key="option?.id">
                  {{ option?.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="Часовой пояс" name="timezone" :rules="[
              { required: true, message: 'Пожалуйста, выберите вариант', trigger: 'blur' },
            ]">
              <a-select v-model:value="formInfo.timezone" show-search
                        :default-active-first-option="false"
                        :show-arrow="true"
                        :filter-option="false"
                        :not-found-content="null"
                        @search="onTimezoneSearch"
                        placeholder="Выберите часовой пояс" size="large">
                <a-select-option :value="option.value" v-for="(option) in timezoneList" :key="option.value">
                  {{ option.text }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="Превью" name="preview">
              <app-input-file v-model="formInfo.fileList" placeholder="Выберите изображение"/>
            </a-form-item>
            <a-form-item label="QR-код">
              <div :style="{ margin: '0.5rem 0 1.5rem' }">
                <img :src="qrCode" :alt="formInfo.name" width="200"
                     class="qr-code" :class="{ loading: loadingQrCode }">
              </div>
              <a-popconfirm ok-text="Заменить QR-код" cancel-text="Отмена"
                            @confirm="getQRCode">
                <template #title>
                  <h6>Заменить QR-код? </h6>
                  Это действие не требует сохранения и его нельзя отменить. <br/>
                  Текущий QR-код объекта станет недействительным
                </template>
                <a-button size="large" :loading="loadingQrCode">Создать новый код</a-button>
              </a-popconfirm>
            </a-form-item>
            <div class="form-footer">
              <a-button size="large" @click="handleCancel">Отменить</a-button>
              <a-button type="primary" size="large" @click="handleSubmit('info')" :loading="loadingProgress"
                        :disabled="!isFormChanged('info')">
                Сохранить
              </a-button>
            </div>
          </a-form>
        </a-tab-pane>
        <!--  Аттрибуты  -->
        <a-tab-pane key="attributes" tab="Атрибуты" :force-render="true">
          <attributes-editor v-model="formAttributes.attributes" @load="fixChanges"/>
          <div class="form-footer">
            <a-button size="large" @click="handleCancel">Отменить</a-button>
            <a-button type="primary" size="large" @click="handleSubmit('attributes')" :loading="loadingProgress"
                      :disabled="!isFormChanged('attributes')">
              Сохранить
            </a-button>
          </div>
        </a-tab-pane>
        <!--  SVG-карта  -->
        <a-tab-pane key="map" tab="SVG-карта">
          <svg-map-editor :objects="formMap.mapObjects" v-model:url="formMap.mapImage"
                          :parent-id="formInfo?.id"/>
          <div class="form-footer" :style="{ paddingBottom: '130px' }">
            <a-button size="large" @click="handleCancel">Отменить</a-button>
            <a-button type="primary" size="large" @click="handleSubmit('map')" :loading="loadingProgress"
                      :disabled="!isFormChanged('map')">
              Сохранить
            </a-button>
          </div>
        </a-tab-pane>
        <!--  Доступы  -->
        <a-tab-pane key="access" tab="Доступы">
          <access-editor :object-id="formInfo?.id"/>
        </a-tab-pane>
        <!--  Бронирования  -->
        <a-tab-pane key="reservations" tab="Бронирования">
          <object-reservations :object-id="formInfo?.id"/>
        </a-tab-pane>
      </a-tabs>
    </main>

    <response-question-modal
        v-model:visible="isResponseQuestion"
        :message="responseQuestionMessage" :admin-message="responseQuestionAdminMessage"
        :proceed-param="responseQuestionParam" @proceed="handleResponseQuestionProceed"/>

  </section>
</template>

<script>
import { computed, ref, unref } from "@vue/reactivity";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { onMounted, watch } from "@vue/runtime-core";
import moment from "moment-timezone";
import { fileHelper, numberHelper, urlHelper } from "@/compositions/commonFunctions";
import {
  DeleteOutlined, ReloadOutlined, EnvironmentOutlined,
} from '@ant-design/icons-vue';
import AppInputFile from "@/components/admin/shared/ul/AppInputFile";
import { getOneData, requestAPI } from "@/compositions/objects";
import { getListData as getObjectTypesData } from "@/compositions/objectTypes";
import AttributesEditor from "@/components/admin/AttributesEditor";
import { Modal, notification } from "ant-design-vue";
import { resourcesData } from "@/compositions/resources";
import { validatorFunctions } from "@/compositions/validators";
import SvgMapEditor from "@/components/admin/objects/SvgMapEditor";
import AccessEditor from "@/components/admin/objects/AccessEditor";
import { FETCH_RESERVATION_POLICIES } from "@/store/politics";
import { keepUnsavedChangesManager } from "@/compositions/keepUnsavedChangesManager";
import ObjectReservations from "@/components/admin/objects/ObjectReservations";
import ResponseQuestionModal from "@/components/admin/politics/ResponseQuestionModal";
import { dateHelper } from "../../../compositions/commonFunctions";

export default {
  name: "ObjectsEditPage",
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const loading = ref(true);
    const loadingProgress = ref(false);
    const loadingQrCode = ref(false);
    const currentTab = ref('info');
    const pageTitle = ref('Структура');
    const refFormInfo = ref(null);
    const politicsList = ref([]);
    const objectPath = ref([]);
    const backRoute = computed(() => unref(objectPath)?.slice(-1).pop());
    const qrCode = ref('');
    const timezoneList = ref([]);

    const isResponseQuestion = ref(false);
    const responseQuestionMessage = ref('');
    const responseQuestionAdminMessage = ref('');
    const responseQuestionPayload = ref({});
    const responseQuestionParam = ref('override');

    const { uploadFile } = resourcesData();
    const { getObjectById, updateObject, removeObject, generateQRCode, generateUniqueCode } = requestAPI();
    const { attributesValidator, svgMapValidator } = validatorFunctions();
    const { formInfo, formAttributes, formMap } = getOneData();
    const { useKeepManager, fixChanges, isFormChanged, formsSaved } = keepUnsavedChangesManager();

    const {
      fetchList: fetchObjectTypes,
      data: objectTypesData,
      search: objectTypesSearch,
    } = getObjectTypesData();

    async function getObjectTypes(search) {
      objectTypesSearch.value = search;
      await fetchObjectTypes();
    }

    async function getPolitics(search) {
      const res = await store.dispatch(FETCH_RESERVATION_POLICIES, { search });
      politicsList.value = res?.data || [];
    }

    async function getQRCode() {
      loadingQrCode.value = true;
      try {
        const res = await generateQRCode(route.params?.id);
        qrCode.value = res?.qrCode;
      } catch {
        notification.error({ message: 'Ошибка', description: 'QR-код не был сгененирован' });
      } finally {
        loadingQrCode.value = false;
      }
    }

    onMounted(async () => {
      if (route.params?.id) {
        loading.value = true;
        const res = await getObjectById(route.params?.id);
        if (res?.id) {
          formInfo.value = { ...res };
          formInfo.value.fileList = [];
          formInfo.value.objectTypeId = res.objectType?.id;
          formInfo.value.reservationPolicyId = res.reservationPolicy?.id;
          objectPath.value = res?.path || [];
          //
          formAttributes.value.attributes = res.attributes;
          formInfo.value.attributes = formInfo.value.qrCode = undefined;
          qrCode.value = res?.qrCode;
          //
          formMap.value.mapImage = res.mapUrl;
          formMap.value.mapObjects = res.mapObjects;
          formInfo.value.mapUrl = formInfo.value.mapObjects = undefined;

          // Заполняем доступные значения для меню
          onTimezoneSearch();
          if (formInfo.value.timezone) {
            const allZones = dateHelper.localizedNames();
            const timezoneOption = allZones.find((option) => option.value === formInfo.value.timezone);
            const timezoneOptionLoaded = timezoneList.value.find((option) => option.value === formInfo.value.timezone);
            if (!timezoneOptionLoaded) {
              timezoneList.value.unshift({ text: timezoneOption.name, value: timezoneOption.value });
            }
          }
          await getPolitics();
          if (res.reservationPolicy && !politicsList.value.find((option) => option.id === res.reservationPolicy?.id)) politicsList.value.unshift(res.reservationPolicy);

          await getObjectTypes();
          if (res.objectType && !objectTypesData.value.find((option) => option.id === res.objectType?.id)) objectTypesData.value.unshift(res.objectType);

          if (res.previewUrl) {
            formInfo.value.fileList.push(await fileHelper.createFileFromUrl(res.previewUrl, 'Файл превью'));
          }
          //
          pageTitle.value = res.name;
          urlHelper.setPageTitle(pageTitle.value);
          useKeepManager({ 'info': formInfo, 'attributes': formAttributes, 'map': formMap }, handleKeepChanges);

          // Открыть нужный таб
          if (route.hash) {
            const hash = route.hash.substr(1);
            if (['info', 'attributes', 'map', 'access', 'reservations'].indexOf(hash) >= 0) currentTab.value = hash;
          }

        } else {
          notification.error({ message: 'Ошибка', description: 'Объект не найден' });
        }
      } else {
        notification.error({ message: 'Ошибка', description: 'Объект не найден' });
        router.push({ name: 'objects' });
      }
      loading.value = false;
    });

    async function handleSubmit(formName) {
      let requestForm = {};

      // Валидируем и сохраняем основную информацию
      if (formName === 'info') {
        await refFormInfo.value
            .validate()
            .then(async () => {
              loadingProgress.value = true;
              requestForm = unref(formInfo);
              requestForm.reservationPolicyId = requestForm.reservationPolicyId ?? null;
              //
              requestForm.objectType =
                  requestForm.attributes =
                      requestForm.mapImage =
                          requestForm.mapObjects =
                              requestForm.mapUrl = undefined;
              // Конвертировать файлы в base64
              requestForm.previewImage = null;
              if (requestForm.fileList.length > 0) {
                const file = requestForm.fileList[0];
                if (file?.url) {
                  requestForm.previewImage = file?.url;
                } else {
                  const previewImageBase64 = await fileHelper.createBase64FromFile(file);
                  const previewImage = await uploadFile(previewImageBase64, route.name);
                  requestForm.previewImage = previewImage?.url;
                }
              }
            })
            .finally(() => {
              loadingProgress.value = false;
            });
      }

      // Сохраняем атрибуты
      if (formName === 'attributes') {
        requestForm = unref(formAttributes);

        // Проверка заполнены ли атрибуты корректно
        // (добавьте true, если нужно проверять значения)
        if (!await attributesValidator(requestForm.attributes)) {
          return notification.error({
            message: 'Проверьте атрибуты',
            description: 'В таблице есть незаполненные поля',
          });
        }
      }

      // Сохраняем карту
      if (formName === 'map') {
        requestForm = unref(formMap);
        if (!requestForm.mapImage) requestForm.mapImage = null;

        // Проверка заполнены ли идентификаторы карты корректно
        if (!await svgMapValidator(requestForm.mapObjects)) {
          return notification.error({
            message: 'Проверьте данные',
            description: 'В таблице есть незаполненные поля',
          });
        }
      }
      // ... Другие формы ...

      // Отправляем выбранную проверенную форму
      await trySave(requestForm, formName, {});
    }

    async function trySave(requestForm, formName, params = {}) {
      try {
        loadingProgress.value = true;
        const res = await updateObject(requestForm, route.params?.id, params);
        if (res?.id) {
          await handleSubmitResponse(res, formName);
        } else {
          if (res?.errorCode === 'error_update') {
            responseQuestionParam.value = 'override';
          }
          if (res?.errorCode === 'error_object_code_update') {
            responseQuestionParam.value = 'force';
          }
          // Предлагаем удалить все бронирования, которые не подходят под изменения
          responseQuestionMessage.value = res?.message;
          responseQuestionAdminMessage.value = res?.messageAdmin;
          isResponseQuestion.value = true;
          responseQuestionPayload.value = { formName, requestForm };
        }
      } catch (error) {
        console.error({ message: 'Ошибка отправки данных' });
      } finally {
        loadingProgress.value = false;
      }
    }

    async function handleResponseQuestionProceed(params) {
      console.log(params);
      if (params.force === false) {
        // Ответили нет - выходим из сохранения
        isResponseQuestion.value = false;
        return notification.info({
          message: 'Уведомление',
          description: 'Сохранение прервано',
        });
      }
      if (params.override !== undefined) {
        params.force = true;
      }
      const requestForm = unref(responseQuestionPayload)?.requestForm;
      await trySave(requestForm, unref(responseQuestionPayload)?.formName, params)
    }

    async function handleSubmitResponse(res, formName) {
      if (res?.id) {
        notification.success({
          message: 'Успех',
          description: 'Изменения сохранены',
        });
        // Новый заголовок
        pageTitle.value = res.name;
        urlHelper.setPageTitle(pageTitle.value);
        fixChanges(formName);

        // Если сохраняли основную форму, обновить страницу атрибутов
        if (formName === 'info') {
          formAttributes.value.attributes = res.attributes;
          fixChanges('attributes');
        }

        isResponseQuestion.value = false;
      } else {
        notification.error({ message: 'Ошибка сохранения', description: 'Попробуйте позже' });
      }
    }

    async function handleDelete() {
      if (unref(formInfo)?.hasChildren) {
        Modal.info({
          title: 'Удаление невозможно',
          content: 'Невозможно удалить объект. У него есть дочерние объекты',
          okText: 'Отменить',
          okType: 'default',
        });
      } else {
        Modal.confirm({
          title: 'Удалить этот объект?',
          okText: 'Удалить',
          okType: 'danger',
          cancelText: 'Отмена',
          async onOk() {
            await removeObject(route.params?.id).then(() => {
              fixChanges();
              notification.success({
                message: 'Успех',
                description: 'Запись успешно удалена',
              });
              router.push({ name: 'objects' });
            });
          },
          onCancel() {
          },
        });
      }
    }

    function handleCancel() {
      router.push({ name: 'objects' });
    }

    function handleKeepChanges(changedForms) {
      if (changedForms.length > 0) currentTab.value = changedForms[0];
    }

    async function getUniqueCode() {
      const code = await generateUniqueCode();
      if (code) {
        formInfo.value.code = code;
      } else {
        formInfo.value.code = numberHelper.uuid();
      }
      try {
        refFormInfo.value.clearValidate('code');
      } catch (e) {
        console.error(e);
      }
    }

    function onTimezoneSearch(searchText) {
      const allZones = dateHelper.localizedNames();
      let list = [];
      if (!searchText) {
        // Фильтровать по локализованному списку внутри страны
        const russianLocales = moment.tz.zonesForCountry('RU');
        list = allZones.filter(({ value }) => russianLocales.indexOf(value) !== -1);
      } else {
        const q = searchText.toLowerCase();
        list = allZones.filter(({ name }) => name.toLowerCase().indexOf(q) !== -1);
      }
      timezoneList.value = list.map(({ name, value }) => ({ text: name, value }));
    }

    function onObjectTypeChanged() {
      if (isFormChanged('attributes')) {
        notification.info({
          message: 'Тип объекта изменен',
          description: 'Сохраните изменения в атрибутах. Несохраненные изменения будут потеряны'
        });
        currentTab.value = 'attributes';
      }
    }

    watch(currentTab, (tab) => {
      window.location.hash = tab;
    });

    return {
      pageTitle,
      loading,
      loadingProgress,
      loadingQrCode,
      currentTab,
      fixChanges,
      isFormChanged,
      formsSaved,
      objectPath,
      backRoute,
      formInfo,
      qrCode,
      refFormInfo,
      politicsList,
      getPolitics,
      getQRCode,
      onObjectTypeChanged,
      formAttributes,
      formMap,
      //
      objectTypesData,
      objectTypesSearch,
      getObjectTypes,
      getUniqueCode,
      timezoneList,
      onTimezoneSearch,
      handleSubmit,
      handleDelete,
      handleCancel,
      isResponseQuestion,
      responseQuestionMessage,
      responseQuestionAdminMessage,
      handleResponseQuestionProceed,
      responseQuestionParam,
    }
  },
  components: {
    ResponseQuestionModal,
    ObjectReservations,
    AccessEditor, SvgMapEditor, AttributesEditor,
    AppInputFile,
    DeleteOutlined, EnvironmentOutlined, ReloadOutlined,
  }
}
</script>

<style lang="less">
.qr-code {
  transition: 0.5s ease-in-out;

  &.loading {
    opacity: 0.1;
  }
}
</style>
